<template>
  <div class="order">
    <div class="order-head">
      <div class="order-head-l">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            v-for="(item, index) in tabData"
            :key="index"
            :label="item.name"
            :name="item.name"
          >
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="order-head-r">
        <div class="order-head-r-select">
          <el-select v-model="bill_type" placeholder="请选择">
            <el-option
              v-for="item in bill_type_options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="order-head-r-input">
          <el-input
            placeholder="请输入内容"
            v-model="datas.code"
            clearable
            @input="inputs"
            @clear="getList"
          >
          </el-input>
        </div>
        <div class="order-head-r-time">
          <el-date-picker
            style="width:100%;"
            v-model="datas.time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="clearDate"
            :default-time="['00:00:00', '00:00:00']"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div class="order-head-r-btn">
          <el-button type="primary" @click="search">搜索</el-button>
        </div>
      </div>
    </div>
    <div class="order-cont" v-if="tabDataIndex">
      <el-table
        :data="tabData[tabDataIndex].data"
        :border="$table('border')"
        :stripe="$table('stripe')"
        key="1"
        :header-cell-style="{ background: $table('bgcolor') }"
        v-if="tabDataIndex"
      >
        <el-table-column prop="code" label="订单编号">
          <template slot-scope="scope">
            {{
              scope.row.order
                ? scope.row.code || scope.row.order.code
                : scope.row.id
            }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="name" label="图片">
          <template slot-scope="scope">
            <div v-if="scope.row.goods_info">
              <img
                style="width:50px;height:50px"
                v-if="
                  scope.row.goods_info.picture && scope.row.goods_info.picture
                "
                :src="scope.row.goods_info.picture"
                :alt="scope.row.name"
              />
              <img
                v-else
                style="width:50px;height:50px"
                src="../../assets/none.png"
                :alt="scope.row.name"
              />
            </div>
            <div v-else>
              <img
                style="width:50px;height:50px"
                src="../../assets/none.png"
                :alt="scope.row.name"
              />
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="name" label="名称" v-if="tabDataIndex <= 5">
          <template slot-scope="scope">
            {{ scope.row.name || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="类目" v-else>
          <template slot-scope="scope">
            {{ scope.row.name || "--" }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="address"
          label="单价（￥）"
          v-if="tabDataIndex <= 5"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.goods_info">
              {{
                scope.row.goods_info.length
                  ? scope.row.goods_info.price
                  : scope.row.price_sale
              }}
            </div>
            <div v-else>
              {{ scope.row.amount }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="数量" v-if="tabDataIndex <= 5">
          <template slot-scope="scope"> x{{ scope.row.num || "1" }} </template>
        </el-table-column> -->
        <el-table-column prop="address" label="支付方式">
          <template slot-scope="scope">
            <div v-if="scope.row.payments">
              <span v-if="scope.row.payments.length">
                {{ getPayType(scope.row.payments[0].pay_type) }}
              </span>
              <span v-else>其他</span>
            </div>
            <span v-else>{{ getPayType(scope.row.pay_type) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="order.sale_amount" label="实收金额（￥）">
          <template slot-scope="scope">
            {{
              scope.row.order ? scope.row.order.sale_amount : scope.row.amount
            }}
          </template>
        </el-table-column>
        <el-table-column label="经手人">
          <template slot-scope="scope">
            <div v-if="tabDataIndex <= 5">
              <div v-if="!scope.row.staffs.length">--</div>
              <div v-else>
                <div v-for="(item, index) in scope.row.staffs" :key="index">
                  {{ item.staff_info.name }}
                </div>
              </div>
            </div>
            <div v-else>
              {{ scope.row.staff.name || "--" }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="address" label="经手人">
          <template slot-scope="scope">
            <div v-if="tabDataIndex <= 5">
              <span v-if="!scope.row.staffs.length">--</span>
              <span v-if="scope.row.staffs.length == 1">{{
                scope.row.staffs[0].staff_info.name
              }}</span>

              <el-dropdown v-if="scope.row.staffs.length > 1">
                <span class="el-dropdown-link">
                  {{ scope.row.staffs[0].staff_info.name
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in scope.row.staffs"
                    :key="index"
                    >{{ item.staff_info.name }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div v-else>
              {{ scope.row.staff.name || "--" }}
            </div>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="member"
          label="消费用户"
          v-if="tabDataIndex <= 5"
        >
          <template slot-scope="scope">
            <!-- {{ scope.row.member ? scope.row.member.name : "散客" }} -->
            <div v-if="scope.row.order">
              <div class="centters" v-if="scope.row.order.member">
                <img
                  src="../../assets/vip1.png"
                  :alt="scope.row.order.member.name"
                />
                <span style="margin-left:5px;color:#F57665;">{{
                  scope.row.order.member.name
                }}</span>
              </div>
              <span v-if="!scope.row.order.member">散客</span>
            </div>
            <div v-else>
              快捷收银
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="order.order_time" label="下单时间">
          <template slot-scope="scope">
            {{ scope.row.order ? scope.row.order.order_time : scope.row.date }}
          </template>
        </el-table-column>
        <el-table-column prop="order.order_time" label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button plain @click="handleEdit(scope.row)" size="mini"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 水单审核 -->
    <div class="order-cont" v-if="!tabDataIndex">
      <el-table
        :data="tabData[tabDataIndex].data"
        :border="$table('border')"
        :stripe="$table('stripe')"
        key="2"
        :header-cell-style="{ background: $table('bgcolor') }"
        v-if="!tabDataIndex"
      >
        <el-table-column prop="code" label="流水号"> </el-table-column>
        <el-table-column prop="bills.type" label="类型">
          <template slot-scope="scope">
            {{
              scope.row.payments.length
                ? getOrderType(scope.row.bills[0].type)
                : "其他"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="member" label="客户信息">
          <template slot-scope="scope">
            <div v-if="scope.row.member">
              <p>{{ scope.row.member.name || "散客" }}</p>
              <p>{{ scope.row.member.phone || "--" }}</p>
              <!-- <p>{{scope.row.member.names || '散客'}}</p> -->
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column prop="payments" label="支付明细">
          <template slot-scope="scope">
            <p>{{ getPayType(scope.row.payments[0].pay_type) }}</p>
            <!-- <p v-if="scope.row.bills.length">
              <span
                >优惠：{{
                  (
                    scope.row.order_amount -
                    0 -
                    (scope.row.sale_amount - 0)
                  ).toFixed(2)
                }}</span
              >
              <span v-if="scope.row.bills.disc - 0"
                >{{ scope.row.bills.disc }}折</span
              >
            </p> -->
            <p>实收：￥{{ scope.row.sale_amount }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="bills" label="消费项目" width="160px">
          <template slot-scope="scope">
            <div
              class="order-infos-cont-item-img order-infos-cont-item-imgs"
              v-for="(item, index) in scope.row.bills"
              :key="index"
            >
              <img
                v-if="item.goods_info.picture"
                :src="item.goods_info.picture"
                :alt="item.goods_info.name"
              />
              <img
                v-else
                src="../../assets/none.png"
                :alt="item.goods_info.name"
              />
              <el-popover
                placement="top-start"
                title="商品名称："
                width="160"
                trigger="hover"
                :content="item.goods_info.name ? item.goods_info.name : '--'"
              >
                <!-- <el-button slot="reference">hover 激活</el-button> -->
                <!-- <i class="el-icon-s-comment" slot="reference"></i> -->
                <p slot="reference">
                  {{ item.goods_info.name ? item.goods_info.name : "--" }}
                </p>
              </el-popover>

              <span>x{{ item.num || 1 }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="bills" label="经手人">
          <template slot-scope="scope">
            <div v-if="getStaffs(scope.row.bills)">
              <p
                v-for="(item, index) in getStaffs(scope.row.bills)"
                :key="index"
              >
                {{ item.name }}({{ item.workID }})
              </p>
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column prop="order_time" label="下单时间"> </el-table-column>
        <el-table-column prop="remark" label="备注">
          <template slot-scope="scope">
            <div v-if="scope.row.remark">
              <el-popover
                placement="top-start"
                title="订单备注"
                width="160"
                trigger="hover"
                :content="scope.row.remark"
              >
                <!-- <el-button slot="reference">hover 激活</el-button> -->
                <i class="el-icon-s-comment" slot="reference"></i>
              </el-popover>
            </div>
            <span v-else style="color:#ccc;">无备注...</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="btn-box">
              <el-button size="mini" @click="show_order(scope.row)"
              >
                审单
              </el-button>
              <el-button type="danger" size="mini" @click="del_order(scope.row)"
              >
                删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-if="$pageStatus(tabData[tabDataIndex].total)[0]">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="$pageStatus(tabData[tabDataIndex].total)[1]"
        layout="total, prev, pager, next, jumper"
        :total="tabData[tabDataIndex].total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="tabData[tabDataIndex].name + '订单详情'"
      :visible.sync="dialogVisible"
      :append-to-body="dialogVisible"
      width="1200px"
    >
      <div class="order-infos" v-if="form.id">
        <!-- 套餐卡 -->
        <div
          class="order-infos-cont"
          v-show="tabDataIndex >= 1 && tabDataIndex < 6"
        >
          <div class="order-infos-cont-item">
            <el-table
              :data="form.goods_info"
              :border="$table('border')"
              :stripe="$table('stripe')"
              key="3"
              v-if="tabDataIndex <= 5"
              :header-cell-style="{ background: $table('bgcolor') }"
            >
              <el-table-column prop="date" label="名称">
                <template slot-scope="scope">
                  <div class="order-infos-cont-item-img">
                    <img
                      v-if="scope.row.picture && scope.row.picture"
                      :src="scope.row.picture"
                      :alt="scope.row.name"
                    />
                    <img
                      v-else
                      src="../../assets/none.png"
                      :alt="scope.row.name"
                    />
                    {{ scope.row.name ? scope.row.name : "--" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="num" label="数量">
                <template>{{ form.num || 0 }}</template>
              </el-table-column>
              <el-table-column prop="price" label="单价">
                <template >{{
                  form.price_sale || "--"
                }}</template>
              </el-table-column>
              <el-table-column prop="amount" label="总价">
                <template >{{
                  form.price_sale
                    ? ((form.num || 1) *form.price_sale).toFixed(2)
                    : 0
                }}</template>
              </el-table-column>
              <el-table-column label="经手人/提成">
                <template slot-scope="scope">
                  <span v-if="0">{{ scope }}</span>
                  <div v-if="!form.staffs.length">--</div>
                  <div v-else>
                    <div v-for="(item, index) in form.staffs" :key="index">
                      {{ item.staff_info.name }}/￥{{item.amount || "0.00"}}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="amount" label="经手人/提成">
                <template slot-scope="scope">
                  <span v-if="!form.staffs.length">--</span>
                  <span v-if="form.staffs.length == 1"
                    >{{ form.staffs[0].staff_info.name }}/￥{{
                      form.staffs[0].amount || "0.00"
                    }}</span
                  >

                  <div v-if="form.staffs.length > 1">
                    <span v-if="0">{{ scope }}</span>
                    <el-dropdown>
                      <div class="el-dropdown-link">
                        {{ form.staffs[0].staff_info.name }}/￥{{
                          form.staffs[0].amount || "0.00"
                        }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </div>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          v-for="(item, index) in form.staffs"
                          :key="index"
                          >{{ item.staff_info.name }}/￥{{
                            item.amount || "0.00"
                          }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
        </div>
        <div class="order-infos-title" v-if="tabDataIndex == 2">套餐内容</div>
        <div class="order-infos-cont" v-if="tabDataIndex == 2">
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list"></div>
            <div class="order-infos-cont-item-list"></div>
          </div>
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list"></div>
            <div class="order-infos-cont-item-list"></div>
          </div>
        </div>
        <!-- 套餐卡 -->

        <!-- 次卡 -->
        <div class="order-infos-title" v-if="tabDataIndex == 3">次卡内容</div>
        <div class="order-infos-cont" v-if="tabDataIndex == 3">
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list"></div>
            <div class="order-infos-cont-item-list"></div>
          </div>
          <div class="order-infos-cont-item"></div>
        </div>
        <!-- 次卡 -->

        <div class="order-infos-title">
          详情信息
        </div>
        <div class="order-infos-cont">
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list">
              <span>订单编号：</span>
              <p>{{ form.order ? form.order.code : form.id }}</p>
            </div>
            <!-- <div class="order-infos-cont-item-list">
              <span>服务门店：</span>
              <p>{{ form.shop ? form.shop.name : "--" }}</p>
            </div> -->
            <div class="order-infos-cont-item-list">
              <span>订单时间：</span>
              <p>{{ form.order ? form.order.order_time : form.date }}</p>
            </div>
            <div class="order-infos-cont-item-list" v-if="tabDataIndex <= 5">
              <span>顾客信息：</span>
              <p v-if="form.order">
                {{
                  form.order.member
                    ? form.order.member.name +
                      "(" +
                      form.order.member.phone +
                      ")"
                    : "散客"
                }}
              </p>
              <p v-else>
                快捷收银
              </p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>备注信息：</span>
              <p :title="form.order.remark" v-if="form.order">
                {{ form.order.remark || "无备注..." }}
              </p>
              <p :title="form.remark" v-else>
                {{ form.remark || "无备注..." }}
              </p>
            </div>
          </div>
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list">
              <span v-if="tabDataIndex == 0">充值金额：</span>
              <span v-if="tabDataIndex > 0">订单金额：</span>
              <p v-if="form.order">￥{{ form.order.order_amount || "0" }}</p>
              <p v-else>￥{{ form.amount || "0" }}</p>
            </div>
            <div v-if="0">
              <div
                class="order-infos-cont-item-list"
                v-show="tabDataIndex == 0"
              >
                <span>赠送金额：</span>
                <p>￥0.00</p>
              </div>
              <div
                class="order-infos-cont-item-list"
                v-show="tabDataIndex >= 1 && tabDataIndex < 5"
              >
                <span>优惠金额：</span>
                <p>
                  ￥-{{ (form.price_o - 0 - (form.price_sale - 0)).toFixed(2) }}
                </p>
              </div>
            </div>
            <div class="order-infos-cont-item-list" v-if="tabDataIndex == 0">
              <span>合计充值：</span>
              <p>
                ￥{{
                  (
                    (form.order.order_amount - 0 || 0) +
                    (form.order.gift_amount - 0 || 0)
                  ).toFixed(2)
                }}
              </p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>支付方式：</span>
              <p v-if="tabDataIndex <= 5">
                {{
                  form.payments.length
                    ? getPayType(form.payments[0].pay_type)
                    : "其他"
                }}
              </p>
              <p v-else>{{ getPayType(form.pay_type) }}</p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>实收金额：</span>
              <p v-if="form.order">￥{{ form.order.sale_amount || "0" }}</p>
              <p v-else>￥{{ form.amount || "0" }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="order-infos" v-show="tabDataIndex == 1">
        <div class="order-infos-cont">
          <div class="order-infos-cont-item">
            <el-table
              :data="form.bills"
              :border="$table('border')"
              :header-cell-style="{ background: $table('bgcolor') }"
            >
              <el-table-column prop="date" label="套餐卡名称">
                <template slot-scope="scope">
                  <div class="order-infos-cont-item-img">
                    <img
                      v-if="
                        scope.row.goods_info && scope.row.goods_info.picture
                      "
                      :src="scope.row.goods_info.picture"
                      :alt="scope.row.name"
                    />
                    <img
                      v-else
                      src="../../assets/none.png"
                      :alt="scope.row.name"
                    />
                    {{ scope.row.name ? scope.row.name : "--" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="num" label="数量"> </el-table-column>
              <el-table-column prop="price_sale" label="单价">
              </el-table-column>
              <el-table-column prop="amount" label="总价"> </el-table-column>
              <el-table-column prop="amount" label="经手人/提成">
                <template slot-scope="scope">
                  <span v-if="!scope.row.staffs.length">--</span>
                  <span v-if="scope.row.staffs.length == 1"
                    >{{ scope.row.staffs[0].staff_info.name }}/￥{{
                      scope.row.staffs[0].amount || "0.00"
                    }}</span
                  >

                  <el-dropdown v-if="scope.row.staffs.length > 1">
                    <span class="el-dropdown-link">
                      {{ scope.row.staffs[0].staff_info.name }}/￥{{
                        scope.row.staffs[0].amount || "0.00"
                      }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="(item, index) in scope.row.staffs"
                        :key="index"
                        >{{ item.staff_info.name }}/￥{{
                          item.amount || "0.00"
                        }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="order-infos-title">套餐内容</div>
        <div class="order-infos-cont">
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list"></div>
            <div class="order-infos-cont-item-list"></div>
          </div>
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list"></div>
            <div class="order-infos-cont-item-list"></div>
          </div>
        </div>
        <div class="order-infos-title">订单详情</div>
        <div class="order-infos-cont">
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list"></div>
            <div class="order-infos-cont-item-list"></div>
          </div>
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list"></div>
            <div class="order-infos-cont-item-list"></div>
          </div>
        </div>
      </div> -->

      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false"
          >关闭详情</el-button
        >
      </span>
    </el-dialog>

    <!-- 水单审核/详情 -->

    <el-dialog
      title="审核订单"
      :visible.sync="dialogVisible1"
      :append-to-body="dialogVisible1"
      width="1200px"
    >
      <div class="order-infos" v-if="form1.id">
        <div class="order-infos-cont">
          <div class="order-infos-cont-item">
            <el-table
              :data="form1.bills"
              :border="$table('border')"
              :stripe="$table('stripe')"
              key="7"
              :header-cell-style="{ background: $table('bgcolor') }"
            >
              <el-table-column prop="goods_info" label="名称">
                <template slot-scope="scope">
                  <!-- {{scope.row.goods_info}} -->
                  <div
                    class="order-infos-cont-item-img"
                    v-if="scope.row.type != 1"
                  >
                    <img
                      v-if="scope.row.goods_info.picture"
                      :src="scope.row.goods_info.picture"
                      :alt="scope.row.goods_info.name"
                    />
                    <img
                      v-else
                      src="../../assets/none.png"
                      :alt="scope.row.goods_info.name"
                    />
                    {{
                      scope.row.goods_info.name
                        ? scope.row.goods_info.name
                        : "--"
                    }}
                  </div>
                  <span v-else>充值</span>
                </template>
              </el-table-column>
              <el-table-column prop="num" label="数量"></el-table-column>
              <el-table-column prop="price_sale" label="单价">
                <!-- <template slot-scope="scope">{{
                  scope.row.price || "--"
                }}</template> -->
              </el-table-column>
              <el-table-column prop="amount" label="总价">
                <!-- <template>{{ form1.order_amount || 0 }}</template> -->
              </el-table-column>
              <!-- <el-table-column prop="staffs" label="经手人"> -->
              <el-table-column prop="staffs" label="提成" width="500">
                <!-- <template slot-scope="scope">
                  <span v-if="!scope.row.staffs.length">--</span>
                  <span v-if="scope.row.staffs.length == 1">{{
                    scope.row.staffs[0].staff_info.name
                  }}</span>

                  <div v-if="scope.row.staffs.length > 1">
                    <span v-if="0">{{ scope }}</span>
                    <el-dropdown>
                      <div class="el-dropdown-link">
                        {{ scope.row.staffs[0].staff_info.name
                        }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </div>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          v-for="(item, index) in scope.row.staffs"
                          :key="index"
                          >{{ item.staff_info.name }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </template> -->
                <template slot-scope="scope">
                  <span v-if="!scope.row.staffs.length">
                    <el-button
                      size="medium"
                      @click="handleOpenDialogChild(scope.row)"
                    >编辑</el-button>
                  </span>
                  <span v-if="scope.row.staffs.length == 1">
                    <el-table
                      :data="scope.row.staffs"
                      border
                      style="width: 100%"
                    >
                      <el-table-column prop="staff_info.name" label="经手人" min-width="133">
                        <template slot-scope="scopeChild">
                          <div>
                            <span>{{scopeChild.row.staff_info.name}}</span> <el-tag size="mini" v-if="scopeChild.row.specify === 0">非指定</el-tag> <el-tag size="mini" v-if="scopeChild.row.specify === 1">指定</el-tag>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="yeji_amount" label="业绩" min-width="115"></el-table-column>
                      <el-table-column prop="amount" label="提成金额" min-width="115"></el-table-column>
                      <el-table-column label="操作" min-width="115">
                        <!-- <template slot-scope="scopeChild">
                          <el-button
                            size="medium"
                            @click="handleOpenDialogChild(scopeChild.row)"
                          >编辑</el-button>
                        </template> -->
                        <el-button
                          size="medium"
                          @click="handleOpenDialogChild(scope.row)"
                        >编辑</el-button>
                      </el-table-column>
                    </el-table>
                  </span>
                  <div v-if="scope.row.staffs.length > 1">
                    <el-table
                      :data="scope.row.staffs"
                      border
                      style="width: 100%"
                    >
                      <el-table-column prop="staff_info.name" label="经手人">
                        <template slot-scope="scopeChild">
                          <div>
                            <span>{{scopeChild.row.staff_info.name}}</span> <el-tag size="mini" v-if="scopeChild.row.specify === 0">非指定</el-tag> <el-tag size="mini" v-if="scopeChild.row.specify === 1">指定</el-tag>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="amount" label="提成金额"></el-table-column>
                      <el-table-column label="操作">
                        <!-- <template slot-scope="scopeChild">
                          <el-button
                            size="medium"
                            @click="handleEdit(scopeChild.$index, scopeChild.row)"
                          >编辑</el-button>
                        </template> -->
                        <el-button
                          size="medium"
                          @click="handleOpenDialogChild(scope.row)"
                        >编辑</el-button>
                      </el-table-column>
                    </el-table>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="order-infos-title">
          详情信息
        </div>
        <div class="order-infos-cont">
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list">
              <span>订单编号：</span>
              <p>{{ form1.code }}</p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>服务门店：</span>
              <p>{{ form1.shop ? form1.shop.name : "--" }}</p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>订单时间：</span>
              <p>{{ form1.order_time }}</p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>顾客信息：</span>
              <p>
                {{
                  form1.member
                    ? form1.member.name + " (" + form1.member.phone + ")"
                    : "散客"
                }}
              </p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>备注信息：</span>
              <p :title="form1.remark">{{ form1.remark || "无备注..." }}</p>
            </div>
          </div>
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list">
              <span>支付方式：</span>
              <p>
                {{
                  form1.payments.length
                    ? getPayType(form1.payments[0].pay_type)
                    : "其他"
                }}
              </p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>订单金额：</span>
              <p>￥{{ form1.order_amount }}</p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>实收金额：</span>
              <p>￥{{ form1.sale_amount }}</p>
            </div>
          </div>
        </div>
      </div>
      <el-dialog width="695px" title="修改业绩提成" :visible.sync="dialogVisibleChild" append-to-body>
        <!-- 如果不想展示错误提示信息,可以加上show-message参数 -->
        <el-form :model="productRuleForm" ref="productRuleForm" :rules="productRuleForm.rules" :show-message="false" class="product-rule-form">
          <el-table
            :data="productRuleForm.tableData"
            border
            style="width: 100%">
            <el-table-column
              prop="name"
              label="经手人"
              width="140"
            >
              <template slot-scope="scopeChild">
                <el-form-item
                  :prop="'tableData.' + scopeChild.$index + '.name'"
                  :rules="productRuleForm.rules.name"
                >
                  <!-- <el-input size="small" v-model="scopeChild.row.name" v-if="scopeChild.row.staff_id"></el-input> -->
                  <el-select size="small" v-model="scopeChild.row.staff_id" @visible-change="handleStaffChange($event, scopeChild.row.staff_id)" placeholder="请选择">
                    <el-option
                      v-for="item in storeStaffList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="specify"
              label="指定"
              width="150"
            >
              <template slot-scope="scopeChild">
                <el-form-item
                  :prop="'tableData.' + scopeChild.$index + '.specify'"
                  :rules="productRuleForm.rules.specify"
                >
                  <el-radio-group size="small" v-model="scopeChild.row.specify">
                    <el-radio-button label="0">非指定</el-radio-button>
                    <el-radio-button label="1">指定</el-radio-button>
                  </el-radio-group>
                  <!-- <el-input v-model="scopeChild.row.specify"></el-input> -->
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="yeji"
              label="业绩"
              width="140"
            >
              <template slot-scope="scopeChild">
                <el-form-item
                  :prop="'tableData.' + scopeChild.$index + '.yeji'"
                  :rules="productRuleForm.rules.yeji"
                >
                  <el-input size="small" v-model="scopeChild.row.yeji"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="amount"
              label="提成"
              width="140"
            >
              <template slot-scope="scopeChild">
                <el-form-item
                  :prop="'tableData.' + scopeChild.$index + '.amount'"
                  :rules="productRuleForm.rules.amount"
                >
                  <el-input size="small" v-model="scopeChild.row.amount"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="staff_id"
              label="操作"
            >
              <template slot-scope="scopeChild">
                <el-form-item
                  :prop="'tableData.' + scopeChild.$index + '.staff_id'"
                  :rules="productRuleForm.rules.staff_id"
                >
                  <el-button size="small" @click="handleRemoveFormItem(scopeChild.$index, scopeChild.row.staff_id)" type="danger" plain>删除</el-button>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
          <el-button class="product-rule-form-btn" size="medium" type="primary" plain icon="el-icon-circle-plus-outline" @click="handleProductRuleFormPush">添加</el-button>
        </el-form>
        <!-- <el-button type="primary" class="submit_btn" @click="submit">提交</el-button> -->
        <!-- <el-form :model="formChild">
          <el-form-item label="提成金额">
            <el-input v-model="formChild.staff_commi" autocomplete="off"></el-input>
          </el-form-item>
        </el-form> -->
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleChild = false">取 消</el-button>
          <el-button type="primary" @click="handleEditCommission">确 定</el-button>
        </div>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取消审单</el-button>
        <el-button type="primary" @click="success_order">确认审单</el-button>
      </span>
    </el-dialog>
    <!-- 水单审核/详情 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabData: [
        {
          name: "水单审核",
          data: [],
          page: 1,
          total: 1,
          key: "",
          time: "",
          type: null,
          status: 0,
          api: "/api/app/meiye/finance/order_store",
        },
        {
          name: "充值",
          data: [],
          page: 1,
          total: 1,
          key: "",
          time: "",
          type: "1",
          api: "/api/app/meiye/finance/bill_store",
        },
        {
          name: "购卡",
          data: [],
          page: 1,
          total: 1,
          key: "",
          time: "",
          type: "2",
          api: "/api/app/meiye/finance/bill_store",
        },
        {
          name: "充次",
          data: [],
          page: 1,
          total: 1,
          key: "",
          time: "",
          type: "3",
          api: "/api/app/meiye/finance/bill_store",
        },
        {
          name: "商品",
          data: [],
          page: 1,
          total: 1,
          key: "",
          time: "",
          type: "4",
          api: "/api/app/meiye/finance/bill_store",
        },
        {
          name: "项目",
          data: [],
          page: 1,
          total: 1,
          key: "",
          time: "",
          type: "5",
          api: "/api/app/meiye/finance/bill_store",
        },
        {
          name: "快捷收银",
          data: [],
          page: 1,
          total: 1,
          key: "",
          time: "",
          type: "1",
          api: "/api/app/meiye/shop_finance/store",
        },
      ], //tab选项卡
      activeName: "水单审核",
      tabDataIndex: 0, //tab下标
      tableData: [],
      dialogVisible: false,
      dialogVisibleChild: false,
      dialogVisible1: false,
      search_type: false, //搜索标识
      type: false, //编辑标识
      options: [
        {
          value: "1",
          label: "储值卡充值",
        },
        {
          value: "2",
          label: "购买套餐卡",
        },
        {
          value: "3",
          label: "项目充次",
        },
        {
          value: "4",
          label: "购买产品",
        },
        {
          value: "5",
          label: "购买项目",
        },
      ],
      options1: [
        {
          value: 1,
          label: "现金",
        },
        {
          value: 2,
          label: "微信",
        },
        {
          value: 3,
          label: "支付宝",
        },
        {
          value: 4,
          label: "银行卡",
        },
        {
          value: 5,
          label: "储值卡(余额)",
        },
        {
          value: 7,
          label: "核销",
        },
        {
          value: 11,
          label: '美团',
        }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      bill_type_options: [{
        value: '1',
        label: '储值卡充值'
      }, {
        value: '2',
        label: '购卡'
      }, {
        value: '3',
        label: '项目充次'
      }, {
        value: '4',
        label: '购买商品'
      }, {
        value: '5',
        label: '购买项目'
      }],
      bill_type: '',
      datas: {
        page: 1,
        total: 1,
        pay_type: "",
        category: "",
        bill_type: "1",
        type: "1",
        code: "",
        time: "",
        date1: "",
        date2: "",
        staff_id: "",
        shop_id: 0,
      },
      showOrderParameter: '', // show_order() 方法的参数
      form: {},
      form1: {},
      bill_id: '',
      staff_id: '',
      staff_commi: [], // 修改提成需要传给后端的参数列表
      storeStaffList: [], // 店铺员工列表
      storeStaffListInit: [], // 初始化提成员工列表
      productRuleForm: {
        tableData: [{
          name: '',
          specify: '',
          yeji: '',
          amount: '',
          staff_id: '',
          disabled: '',
        }],
        rules: {
          name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          specify: [{ required: true, message: '', trigger: 'blur' }],
          yeji: [{ required: true, message: '请输入业绩金额', trigger: 'blur' }],
          amount: [{ required: true, message: '请输入提成金额', trigger: 'blur' }],
          staff_id: [{ required: true, message: '', trigger: 'blur' }],
          disabled: [{ required: true, message: '', trigger: 'blur' }]
        }
      },
    };
  },
  methods: {
    /**
     * 修改提成
     */
    submit() {
      this.$refs['productRuleForm'].validate((valid) => {
        console.log('valid', valid)
      })
    },

    /* 恢复搜索，搜索清除恢复内容 */
    inputs(val) {
      if (!val) {
        this.getList();
      }
    },
    /* 切换选项卡 */
    handleClick(tab) {
      let index = tab.index - 0;
      this.tabDataIndex = index;
      let d = this.tabData[index];

      this.datas.page = d.page;
      this.datas.total = d.total;
      this.datas.bill_type = index;
      this.datas.time = d.time;
      this.datas.code = d.key;
      this.datas.type = d.type;

      this.getList();
      console.log(tab.index);
    },
    /* 获取支付方式 */
    getPayType(obj) {
      for (let i = 0; i < this.options1.length; i++) {
        if (obj - 0 == this.options1[i].value - 0) {
          return this.options1[i].label;
        }
      }
    },
    /* 获取类型 */
    getOrderType(obj) {
      let len = this.tabData.length;
      let d = this.tabData;
      for (let i = 0; i < len; i++) {
        if (obj - 0 == d[i].type - 0) {
          return d[i].name;
        }
      }
    },
    /* 获取员工 */
    getStaffs(obj, type) {
      if (!obj.length) {
        return false;
      }
      let arr = [];
      let len = obj.length;
      for (let i = 0; i < len; i++) {
        obj[i].types = "";
        /* 员工 */
        if (!type) {
          for (let l = 0; l < obj[i].staffs.length; l++) {
           if(obj[i].staffs[l].staff_info){
              arr.push(obj[i].staffs[l].staff_info);
           }
          }
        }
        /* 商品名 */
        if (type) {
          arr.push(obj[i]);
        }
      }
      let arr_sort = [];
      arr = arr.reduce(function(item, next) {
        arr_sort[next.name]
          ? ""
          : (arr_sort[next.name] = true && item.push(next));
        return item;
      }, []);
      return arr.length ? arr : false;
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      // this.datas.page = val;

      this.tabData[this.tabDataIndex].page = val;
      this.datas.page = this.tabData[this.tabDataIndex].page;
      this.getList();
    },
    handleCurrentChange(val) {
      this.tabData[this.tabDataIndex].page = val;
      this.datas.page = this.tabData[this.tabDataIndex].page;
      this.getList();
    },
    /* 日期清除 */
    clearDate(val) {
      if (!val) {
        this.datas.time = [];
        this.datas.date1 = null;
        this.datas.date2 = null;
        this.getList();
      }
    },
    search() {
      if (this.datas.time.length) {
        this.datas.date1 = this.datas.time[0];
        this.datas.date2 = this.datas.time[1];
      }

      if (
        !this.bill_type &&
        !this.datas.pay_type &&
        !this.datas.category &&
        !this.datas.code &&
        !this.datas.date2
      ) {
        this.$message({
          message: "请填写搜索条件~",
          type: "warning",
        });
        return false;
      }
      // this.search_type = true;
      this.tabData[this.tabDataIndex].page = 1;
      this.datas.page = this.tabData[this.tabDataIndex].page;
      this.getList();
    },
    getList() {
      let datas = JSON.parse(JSON.stringify(this.datas));
      datas.date1 = this.datas.time[0];
      datas.date2 = this.datas.time[1];

      // if (obj == 2) {
      //   this.datas = {
      //     page: 1,
      //     total: 1,
      //     pay_type: "",
      //     category: "",
      //     bill_type: "",
      //     type:"",
      //     code: "",
      //     time: "",
      //     date1: "",
      //     date2: "",
      //     staff_id: "",
      //     shop_id: 0,
      //   };
      //   this.search_type = false;
      // }

      if (!this.tabDataIndex) {
        // datas.bill_type = null;
        datas.bill_type = this.bill_type;
        datas.status = 0;
      }
      console.log('listHref:', this.tabData[this.tabDataIndex].api)
      this.$axios({
        href: this.tabData[this.tabDataIndex].api,
        data: datas,
      }).then((res) => {
        let d = !this.tabDataIndex ? res.data : res.data.data;
        // let d = res.data.items.length ? res.data : res.data.data;
        this.tabData[this.tabDataIndex].total = d.total;
        // let len = d.items.length;
        // for (let i = 0; i < len; i++) {
        //   //  console.log(d.items[i].bills)
        //   if (d.items[i].bills) {
        //     d.items[i].nums = 0;
        //     for (let l = 0; l < d.items[i].bills.length; l++) {
        //       d.items[i].nums += d.items[i].bills[l].num || 0;
        //     }
        //   }
        // }

        this.tabData[this.tabDataIndex].data = d.items;
        console.log('getlistRes', res.data);
      });
    },
    save_pintuan() {
      let d = JSON.parse(JSON.stringify(this.form));

      if (!d.name) {
        this.$message({
          message: "请输入活动名称",
          type: "warning",
        });
        return false;
      }
      if (!d.begin_time || !d.end_time) {
        this.$message({
          message: "请选择开始时间或结束时间",
          type: "warning",
        });
        return false;
      }

      if (!d.minute && !d.day && !d.time) {
        this.$message({
          message: "请输入团有效期",
          type: "warning",
        });
        return false;
      }

      if (!d.people_count) {
        this.$message({
          message: "请输入拼团人数",
          type: "warning",
        });
        return false;
      }

      if (!d.retail_price) {
        this.$message({
          message: "请输入原价",
          type: "warning",
        });
        return false;
      }

      if (!d.order_price) {
        this.$message({
          message: "请输入拼团价",
          type: "warning",
        });
        return false;
      }

      if (!d.goods_info.length) {
        this.$message({
          message: "请选择商品",
          type: "warning",
        });
        return false;
      }
      d.expire = 0;
      d.expire += (d.day - 0) * 24 * 60;
      d.expire += (d.time - 0) * 60;
      d.expire += d.minute - 0;

      // console.log((d.day - 0) * 24 * 60,(d.time - 0) * 60,d.minute - 0)

      d.is_coutuan = d.is_coutuan ? 1 : 0;

      console.log(d);

      let href = this.type
        ? "/api/app/meiye/pintuan/edit"
        : "/api/app/meiye/pintuan/add";

      this.$axios({
        href: href,
        data: d,
      }).then(() => {
        this.$message({
          message: this.type ? "修改拼团成功" : "添加拼团成功",
          type: "success",
        });
        this.getList();
        this.dialogVisible = false;
      });

      //
    },
    /**
     * 打开提成弹窗
     */
    handleOpenDialogChild(obj) {
      console.log('dialogChildParameter', obj);
      /* this.bill_id = e.order_bill_id;
      this.staff_id = e.staff_id;
      this.formChild.staff_commi = e.amount;
      this.dialogVisibleChild = true; */
  
      this.bill_id = obj.id;
      const staffsArr = obj.staffs;
      this.productRuleForm.tableData = staffsArr.map(item => {
        let itemObj = {};
        itemObj.name = item.staff_info.name;
        itemObj.specify = item.specify;
        itemObj.yeji = item.yeji_amount;
        itemObj.amount = item.amount;
        itemObj.staff_id = item.staff_id;
        const staff_id = item.staff_id;
        const storeStaffListLen = this.storeStaffList.length;
        for (let i = 0; i < storeStaffListLen; i++) {
          if (this.storeStaffList[i].id === staff_id) {
            this.storeStaffList[i].disabled = true;
            break;
          }
        }
        return itemObj;
      });
      this.storeStaffListInit = staffsArr.map(item => {
        let itemObj = {};
        itemObj.name = item.staff_info.name;
        itemObj.specify = item.specify;
        itemObj.yeji = item.yeji_amount;
        itemObj.amount = item.amount;
        itemObj.staff_id = item.staff_id;
        const staff_id = item.staff_id;
        const storeStaffListLen = this.storeStaffList.length;
        for (let i = 0; i < storeStaffListLen; i++) {
          if (this.storeStaffList[i].id === staff_id) {
            this.storeStaffList[i].disabled = true;
            break;
          }
        }
        return itemObj;
      });
      this.dialogVisibleChild = true;
      console.log('storeStaffListInit', this.storeStaffListInit);
    },
    /**
     * 获取店铺所有员工列表
     */
    getStoreStaffList() {
      const token = sessionStorage.getItem("token");
      this.$axios({
        href: "/api/app/meiye/user/store",
        data: {
          token
        },
      }).then((res) => {
        if (res.data) {
          let arr = res.data;
          let newArr = arr.map(item => {
            item.disabled = false;
            return item;
          })
          this.storeStaffList = newArr;
          console.log('storeStaffList', this.storeStaffList)
        }
      });
    },
    /**
     * 用户选择新的店铺员工
     */
    handleStaffChange(event, value) {
      // console.log('event', event);
      // console.log('value', value);
      if (event) {
        this.staff_id = value;
      } else {
        if (this.staff_id != value) {
          this.storeStaffList.forEach(item => {
            if (item.id === this.staff_id) {
              item.disabled = false;
            }
            if (item.id === value) {
              item.disabled = true;
            }
          })
        }
      }
    },
    /**
     * 删除提成人
     */
    handleRemoveFormItem(index, staff_id) {
      // console.log('index', index);
      // console.log('staff_id', staff_id);
      this.productRuleForm.tableData.splice(index, 1);
      this.storeStaffList.forEach(item => {
        if (item.id === staff_id) {
          item.disabled = false;
        }
      })
    },

    /**
     * 添加提成人
     */
    handleProductRuleFormPush() {
      const obj = { name: '', specify: '', yeji: '', amount: '', staff_id: '', disabled: false }
      this.productRuleForm.tableData.push(obj)
    },
    /**
     * 修改提成
     */
    handleEditCommission() {
      if (this.productRuleForm.tableData.length) {
        this.$confirm('确认修改业绩提成?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let staff_commi = this.productRuleForm.tableData.map(item => {
            let parameter = {};
            parameter.staff_id = item.staff_id;
            parameter.yeji = item.yeji;
            parameter.amount = item.amount;
            parameter.specify = item.specify;
            parameter.m = "m01";
            const storeStaffListInitLen = this.storeStaffListInit.length;
            // console.log('this.storeStaffListInit', this.storeStaffListInit);
            for (let i = 0; i < storeStaffListInitLen; i++) {
              if (parameter.staff_id === this.storeStaffListInit[i].staff_id) {
                parameter.m = "m02";
                // console.log('parameter.staff_id', parameter.staff_id);
                // console.log('this.storeStaffListInit[i].staff_id', this.storeStaffListInit[i].staff_id);
                break;
              }
            }
            console.log('parameter', parameter);
            return parameter;
          })
          let staff_commi_remove = [];
          this.storeStaffListInit.forEach(item => {
            const staff_commi_len = staff_commi.length;
            const staff_commi_last_index = staff_commi_len - 1;
            for (let i = 0; i < staff_commi_len; i++) {
              if (item.staff_id === staff_commi[i].staff_id) {
                break;
              }
              if (i === staff_commi_last_index) {
                if (item.staff_id != staff_commi[i].staff_id) {
                  item.m = "m03";
                  staff_commi_remove.push(item);
                }
              }
            }
          })
          staff_commi = JSON.stringify(staff_commi.concat(staff_commi_remove));
          console.log('bill_id', this.bill_id);
          console.log('staff_commi', staff_commi);
          this.$axios({
            href: "/api/app/meiye/finance/edit_staffcommi",
            data: {
              bill_id: this.bill_id,
              staff_commi
            },
          }).then((res) => {
            console.log('editCommission', res);
            if (res.data) {
              if (res.data.successful === 1) {
                this.$message({
                  type: 'success',
                  message: '修改成功！'
                });
                this.dialogVisibleChild = false;
                // this.dialogVisible1 = true;
                this.getList();
                this.show_order(this.showOrderParameter);
              } else {
                this.$message.error(res.data.message);
              }
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      } else {
        this.$message.error('请添加经手人');
      }
    },
    /* handleEditCommission() {
      if (this.formChild.staff_commi) {
        this.$confirm('确认修改业绩提成?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let staff_commi = [{staff_id: this.staff_id, amount: this.formChild.staff_commi, m: "m02"}];
          staff_commi = JSON.stringify(staff_commi);
          console.log('staff_commi', staff_commi);
          this.$axios({
            href: "/api/app/meiye/finance/edit_staffcommi",
            data: {
              bill_id: this.bill_id,
              staff_commi
            },
          }).then((res) => {
            console.log('editCommission', res)
            if (res.data.successful === 1) {
              this.$message({
                type: 'success',
                message: '修改成功！'
              });
              this.dialogVisibleChild = false;
              this.dialogVisible1 = false;
              this.getList();
            } else {
              this.$message.error(res.data.message)
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      } else {
        this.$message.error('请输入提成金额');
      }
    }, */

    handleEdit(obj) {
      this.type = 1;
      let d = JSON.parse(JSON.stringify(obj));
      console.log(d);
      let t = d.expire - 0;
      d.day = parseInt(t / 60 / 24);
      d.time = parseInt((t - d.day * 24 * 60) / 60);
      d.minute = t - d.day * 24 * 60 - d.time * 60;

      d.goods_info = [d.goods_info];
      d.is_coutuan = d.is_coutuan - 0 ? true : false;
      d.order_type = d.order_type ? "1" : "0";
      d.status = d.status > 0 ? "1" : "-1";

      this.form = d;
      this.dialogVisible = true;
    },
    handleDelete(obj) {
      console.log(obj.row.id);

      this.$confirm("此操作将永久删除该拼团活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            href: "/api/app/meiye/pintuan/del",
            data: { id: obj.row.id },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          });
        })
        .catch(() => {});
    },
    /* 删除订单 */
    del_order(obj) {
      console.log(obj);
      this.$confirm("此操作将永久删除此水单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            href: "/api/app/meiye/finance/order_soft_del",
            data: { oid: obj.id },
          }).then((res) => {
            console.log('del_orderRes', res);
            if (res.data) {
              if (res.data.successful === 1) {
                this.getList();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              } else {
                this.$message({
                  type: "error",
                  message: res.data.message,
                });
              }
            }
          });
        })
        .catch(() => {});
    },
    /* 显示审核订单 */
    show_order(obj) {
      console.log('orderObj', obj);
      this.showOrderParameter = obj;
      /* console.log(obj);
      this.form1 = obj;
      this.dialogVisible1 = true; */
      this.$axios({
        href: "/api/app/meiye/finance/order_detail",
        data: { oid: obj.id },
      }).then((res) => {
        console.log('show_order', res)
        if (res.data.bills.length) {
          this.form1 = res.data;
          this.dialogVisible1 = true;
        }
      });
    },
    /* 确定审单 */
    success_order() {
      if (!this.form1.id) {
        return false;
      }
      this.$confirm("此操作将审核该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        this.$axios({
          href: "/api/app/meiye/finance/order_audit",
          data: { oid: this.form1.id },
        }).then((res) => {
          if (res.data) {
            if (res.data.successful === 1) {
              this.$message({
                type: "success",
                message: "审核订单成功!",
              });
              this.getList();
              this.dialogVisible1 = false;
            } else {
              this.$message({
                type: "error",
                message: res.data.message,
              });
            }
          }
        });
      })
      .catch(() => {});
    },
  },

  created() {
    this.getList();
    this.getStoreStaffList();
  },
};
</script>

<style lang="scss" scoped>
.order {
  width: 100%;
  height: auto;
  &-head {
    width: 100%;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    background: #fff;
    display: flex;
    &-l {
      min-width: 400px;
      width: 50%;
      height: 100%;
      // background: red;
    }
    &-r {
      width:50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &-select {
        margin: 0 10px;
      }
      &-input {
        width: 240px;
        height: 100%;
        // background: chartreuse;
      }
      &-time {
        width: 240px;
        height: 100%;
        margin: 0 10px;
        // background: coral;
      }
      &-btn {
        width: 80px;
        height: 100%;
        // background: cornflowerblue;
      }
      // background: chartreuse;
    }
  }
  &-cont {
    width: 100%;
    padding: 10px;
    height: auto;
    box-sizing: border-box;
  }
  &-infos {
    width: 100%;
    height: auto;
    &-title {
      width: 100%;
      height: 60px;
      font-size: 16px;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      font-weight: Bold;
      text-align: left;
      color: #000000;
      line-height: 60px;
    }
    &-cont {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      height: auto;
      display: flex;
      &-item {
        width: 100%;
        height: auto;
        &-img {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          img {
            margin-right: 5px;
            width: 50px;
            height: 50px;
            border-radius: 5px;
          }
        }
        &-imgs {
          // margin-right: 5px;
          width: 100%;
          height: 20px;
          margin-bottom: 2px;
          // background: red;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          // border-radius: 2px;
          img {
            margin-right: 5px;
            width: 20px;
            height: 20px;
            border-radius: 2px;
          }
          p {
            max-width: 80px;
            height: 20px;
            margin-right: 2px;
            line-height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
          // span{

          // }
        }
        &-list {
          width: 90%;
          height: 25px;
          margin-bottom: 10px;
          display: flex;
          span {
            display: inline-block;
            width: 100px;
            height: 100%;
            line-height: 25px;
            text-align: left;
            color: #888;
            // background: chartreuse;
          }
          p {
            padding: 0;
            color: #000;
            margin: 0;
            width: 65%;
            height: 25px;
            line-height: 25px;
            // background: red;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
        }
      }
    }
  }
}
.product-rule-form .el-form-item {
  margin: 0;
}
.product-rule-form .product-rule-form-btn {
  width: 100%;
  margin-top: 5px;
}
</style>

<style moudle>
.btn-box {
  width: 100%;
  height: 100%;
  display: flex;
}
@media screen and (max-width: 1600px) {
  .btn-box {
    display: block;
  }
  .btn-box button {
    float: left;
    margin: 0 5px 5px 0 !important;
  }
  .btn-box button:last-child {
    margin: 0 !important;
  }
}
.order .el-tabs__content {
  /* display: none; */
  background: red;
}

.order .el-tabs__nav-wrap::after {
  width: 0%;
}
.order-head-r-input .el-input__inner {
  border-radius: 20px;
}
.order .centters {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
</style>
